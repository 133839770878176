import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slider,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import * as XLSX from 'xlsx';
import { getSensorType } from '../../../services/localizationService';

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

const RangeSlider = ({ endTime, value, setValue }) => {
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box p={1} sx={{ width: 552, overflow: 'visible' }}>
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        min={0}
        max={endTime}
        getAriaLabel={() => 'Time range'}
        valueLabelFormat={formatTime}
      />
    </Box>
  );
};

RangeSlider.propTypes = {
  endTime: PropTypes.number,
  value: PropTypes.array,
  setValue: PropTypes.func,
};

function SessionExportDialog({ open, handleClose, data, captureName }) {
  const [dataToExport, setDataToExport] = useState({});
  const [length, setLength] = useState(0);
  const [value, setValue] = useState([]);
  const [name, setName] = useState('');
  const [fieldsToExport, setFieldsToExport] = useState([]);

  useEffect(() => {
    if (!open) return;
    const dupData = {};
    const keys = Object.keys(data);
    const fields = [];

    keys.forEach((key) => {
      const fieldName = getSensorType(key.split(':').pop());
      dupData[fieldName] = data[key];
      fields.push({ fieldName, checked: true });
    });

    const firstObject = data[Object.keys(data)[0]];
    const length = firstObject ? firstObject.length : 0;

    setLength(length);
    setDataToExport(dupData);
    setFieldsToExport(fields);
    setValue([0, length]);
  }, [data, open]);

  const close = () => {
    handleClose();
    setTimeout(() => {
      setValue([0, length]);
      setName('');
      setFieldsToExport([]);
      setDataToExport({});
    }, 1000);
  };

  const handleExport = async () => {
    const timestamps = new Set();

    const selectedFields = fieldsToExport.filter(({ checked }) => checked).map(({ fieldName }) => fieldName);

    Object.keys(dataToExport).forEach((sensorType) => {
      if (selectedFields.includes(sensorType)) {
        dataToExport[sensorType].slice(value[0], value[1]).forEach((entry) => timestamps.add(entry.time));
      }
    });

    const headerRow = ['Time', ...selectedFields];
    const rows = Array.from(timestamps)
      .sort()
      .map((timestamp) => {
        const row = [new Date(timestamp).toLocaleString()];

        selectedFields.forEach((sensorType) => {
          const sensorEntries = dataToExport[sensorType] || [];
          const valueEntry = sensorEntries.find((entry) => entry.time === timestamp);
          row.push(valueEntry ? valueEntry.value : '');
        });

        return row;
      });

    const worksheet = XLSX.utils.aoa_to_sheet([headerRow, ...rows]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sensor Data');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    saveAs(excelBlob, name ? `${name}.xlsx` : `${captureName}-data.xlsx`);
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Export to Spreadsheet</DialogTitle>
      <DialogContent sx={{ overflow: 'visible' }}>
        <Typography variant="h7" sx={{ mb: 1 }}>
          File Name
        </Typography>
        <TextField
          margin="dense"
          label=""
          placeholder={`${captureName}-data`}
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Typography variant="h7" sx={{ mb: 1 }}>
          Sensors Data
        </Typography>
        <Box
          mb={1}
          px={6}
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            columnGap: '8px',
          }}
        >
          {fieldsToExport.map(({ fieldName, checked }, i) => (
            <FormControlLabel
              key={fieldName}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => {
                    const newArr = [...fieldsToExport];
                    newArr[i].checked = !newArr[i].checked;
                    setFieldsToExport(newArr);
                  }}
                />
              }
              label={fieldName}
            />
          ))}
        </Box>
        <Typography variant="h7" sx={{ mb: 1 }}>
          Time Range
        </Typography>
        <RangeSlider endTime={length} value={value} setValue={setValue} />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleExport();
            handleClose();
          }}
        >
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SessionExportDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object,
  captureName: PropTypes.string,
};

export default SessionExportDialog;
