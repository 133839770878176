export const friendlyNameConverter = (sensorKey) => {
  const sensorSplit = sensorKey.split(':');
  if (sensorSplit.length !== 2) return getSensorType(sensorSplit.at(-1));
  const sensorType = sensorSplit[1].toLowerCase();
  const sensorName = sensorSplit[0] === 'null' ? '' : sensorSplit[0];
  return `${sensorName} ${getSensorType(sensorType)}`;
};

export const getSensorType = (sensorType) => {
  switch (sensorType.toLowerCase()) {
    case 'random':
      return `RNG`;
    case 'tvoc_data':
    case 'tvoc(ppm)':
      return `VOC`;
    case 'co2_data':
    case 'co2(ppm)':
      return `CO2`;
    case 'temperature_data':
    case 'temp':
      return `Temperature (C)`;
    case 'temperature_data_f':
    case 'temp_f':
      return `Temperature (F)`;
    case 'humidity_data':
    case 'hum':
      return `Humidity (%)`;
    case 'pressure_data':
      return `Pressure (Pa)`;
    case '0.3_per_100_cm^3_data':
    case 'num_particles_0.3':
      return `0.3um Particles`;
    case '0.5_per_100_cm^3_data':
    case 'num_particles_0.5':
      return `0.5um Particles`;
    case '1.0_per_100_cm^3_data':
    case 'num_particles_1.0':
      return `1um Particles`;
    case '2.5_per_100_cm^3_data':
    case 'num_particles_2.5':
      return `2.5um Particles`;
    case '5.0_per_100_cm^3_data':
    case 'num_particles_5.0':
      return `5um Particles`;
    case '10.0_per_100_cm^3_data':
    case 'num_particles_10.0':
      return `10um Particles`;
    case 'pm1.0_ultra_fine_particulates_data':
    case 'pm1.0(ae ppm)':
      return `PM1.0 Particulates`;
    case 'pm2.5_fine_particles_data':
    case 'pm2.5(ae ppm)':
      return `PM2.5 Particulates`;
    case 'pm10.0_data':
    case 'pm10.0(ae ppm)':
      return `PM10.0 Particulates`;
    case 'hcho(mg/m3)':
      return `Formaldehyde (Mg/m3)`;
    default:
      return sensorType;
  }
};
